/* Wrapper styles */
.track-upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Ensures the wrapper spans the full height of the viewport */
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* Enables vertical scrolling for the page */
}

/* Scrollable table styles */
.uploaded-files-table {
  width: 100%; /* Span the full width of the container */
  border-collapse: collapse;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.uploaded-files-table th,
.uploaded-files-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
}

.uploaded-files-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-align: center;
}

.uploaded-files-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.uploaded-files-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

/* Dropzone styles */
.track-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%; /* Reduced width for a banner-like style */
  height: 100px; /* Reduced height */
  border: 2px dashed #cccccc;
  border-radius: 12px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-bottom: 20px;
}

.track-upload-container:hover {
  border-color: #4caf50;
  background-color: #f0fff4;
}

.drop-area {
  text-align: center;
  font-size: 1rem;
  color: #666;
  font-weight: 500;
}

.drop-area.active {
  background-color: #eafaf1;
  border-color: #4caf50;
  color: #4caf50;
}

/* Upload button styles */
.upload-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}

.upload-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.upload-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.upload-button:active {
  transform: scale(0.95);
}

/* Add some spacing for large tables */
.table-wrapper {
  width: 100%;
  margin-top: 10px;
  overflow-x: auto; /* Allows horizontal scrolling for wide tables */
}

/* Styles for Remove All button */
.remove-all-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.remove-all-button:hover {
  background-color: #c82333;
}

/* Styles for individual trash can button */
.remove-file-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #dc3545;
  transition: color 0.3s ease;
}

.remove-file-button:hover {
  color: #a71d2a;
}

.upload-button-container {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px; /* Adds space between the text and button */
}

.upload-status {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding-right: 10px; /* Ensures spacing before the button */
}

.playlist-header {
    background-color: #f8f9fa; /* Light background for tabs */
    padding: 10px 20px; /* Adjust padding for less height */
  }
  
  .tab-container {
    display: flex; /* Arrange tabs horizontally */
    gap: 15px; /* Space between buttons */
    justify-content: center; /* Center-align the buttons */
    margin-top: 10px; /* Add some margin above */
    margin-bottom: 5px; /* Reduce margin below */
  }
  
  .tab {
    background-color: #ffffff; /* Light background for subtlety */
    color: #007bff; /* Blue text for tabs */
    border: 1px solid #007bff; /* Light blue border */
    border-radius: 20px; /* Rounded-pill style for softer look */
    padding: 6px 15px; /* Smaller padding for a compact look */
    font-size: 14px; /* Smaller, less prominent font size */
    cursor: pointer; /* Pointer cursor for interactivity */
    transition: all 0.3s ease; /* Smooth transition for hover and active states */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  }
  
  .tab.active {
    background-color: #007bff; /* Blue background for active tab */
    color: #ffffff; /* White text for active tab */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow for active state */
  }
  
  .tab:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Very light blue background on hover */
    color: #0056b3; /* Slightly darker blue text */
  }
  
  .tab-content {
    padding: 10px;
    border-radius: 4px; /* Rounded corners */
    background-color: #ffffff; /* White background for content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  
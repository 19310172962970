html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto; /* Enable scrolling for the whole page */
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px); /* Full viewport height minus header */
  overflow-y: auto; /* Allow scrolling within the content */
}

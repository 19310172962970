.header {
  background-color: #007BFF; /* Light blue tint */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #0056b3; /* Subtle darker blue border */
}

.nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  color: #ffffff; /* White text for contrast against blue background */
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #d9e9ff; /* Lighter blue on hover */
}

/* User Info Container */
.user-info {
  position: relative; /* Position for dropdown */
  margin-left: auto;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer; /* Show pointer cursor */
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Align menu below the user info */
  left: 0;
  background-color: #ffffff; /* White background */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  z-index: 1000; /* Ensure it appears above other elements */
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Dropdown Button */
.dropdown-menu button {
  background-color: transparent; /* Transparent background */
  color: #007BFF; /* Match header color */
  border: none;
  padding: 8px 12px;
  text-align: left; /* Align text to the left */
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu button:hover {
  background-color: #007BFF; /* Light blue background on hover */
  color: #ffffff; /* White text on hover */
  border-radius: 4px; /* Add slight rounding for hover effect */
}

/* Tag Manager Container */
.tag-manager {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px);
  /* Full viewport height minus the header height */
  box-sizing: border-box;
  overflow: hidden;
  /* Prevent content from spilling outside */
}

.tag-manager h3 {
  text-align: center;
  margin-bottom: 20px;
}

/* Filter and Search Bar Container */
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

.search-box {
  width: 60%;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.search-box:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

/* Columns Layout */
.columns {
  display: flex;
  gap: 20px;
  flex: 1; /* Let the columns take up all available vertical space */
  overflow: hidden; /* Prevent content overflow */
}

/* Individual Column Styling */
.column {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow vertical scrolling inside the column */
}

.column h4 {
  margin-bottom: 15px;
}

/* Tag List Specific Styles */
.tag-list-content {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
  max-height: 100%;
  /* Ensure it doesn't overflow the column */
}

.tag-list-content li {
  display: flex;
  /* Align type and name in a single row */
  justify-content: space-between;
  /* Space out the tag name and type */
  align-items: center;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.tag-list-content li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tag-list-content li.selected {
  background-color: #007bff;
  /* Highlight background color */
  color: #ffffff;
  /* Change text color when selected */
  border-color: #007bff;
  /* Match the border to the highlight */
}

/* Tag Type (Aligned to the Right) */
.tag-type {
  color: #888;
  /* Light gray text for the type */
  font-size: 14px;
  text-align: right;
  /* Ensure text alignment to the right */
}

/* Tag Name (Aligned to the Left) */
.tag-name {
  font-size: 16px;
  flex-grow: 1;
  /* Take up remaining space */
}


/* Placeholder Styling for Other Columns */
.column p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

/* Scrollbar Customization for Tag List */
.tag-list-content::-webkit-scrollbar {
  width: 6px;
}

.tag-list-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.tag-list-content::-webkit-scrollbar-thumb:hover {
  background-color: #007bff;
}

/* Filter Container (Search and Dropdown) */
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* Space between the search box and dropdown */
  margin-bottom: 20px;
}

/* Genre Dropdown */
.genre-dropdown {
  width: 30%;
  /* Reduce the width of the dropdown */
  padding: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.genre-dropdown:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

/* General Styling for Search Box */
.search-box {
  width: 60%;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.search-box:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}


/* Edit and Cancel Buttons */
.edit-button {
  margin-top: 20px;
  margin-right: 10px;
  /* Add space between Save and Cancel buttons */
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #c82333;
}

/* Input and Select Fields for Editing */
.edit-input {
  padding: 8px;
  font-size: 14px;
  width: 80%;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-input:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

/* Align Buttons Horizontally */
.button-container {
  display: flex;
  justify-content: flex-start;
  /* Align buttons to the left */
  gap: 10px;
  /* Add space between buttons */
  margin-top: 20px;
  /* Ensure spacing between buttons and dropdown */
}

.cancel-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px
}
.music-manager {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px); /* Full viewport height minus the header height */
  box-sizing: border-box;
  overflow: hidden; /* Prevent content from spilling outside */
}

.music-manager h3 {
  text-align: center;
}

/* Filter and Search Bar Container */
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

/* Search Box */
.search-box {
  width: 60%;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.search-box:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.filters {
  display: flex;
  align-items: center; /* Vertically align all items */
  justify-content: center; /* Center the filters section horizontally */
  gap: 10px; /* Space between items */
}

.filters-title {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-right: 10px; /* Add small space between the label and the first input */
}



/* Genre and Subgenre Input Fields */
.filter-input {
  padding: 8px;
  font-size: 14px;
  width: 180px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.filter-input:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

/* Modern Checkbox Styling */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Custom Checkbox */
.checkbox-container label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container input[type="checkbox"]:checked+label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.checkbox-container label::after {
  content: '✓';
  position: absolute;
  top: 0;
  left: 4px;
  font-size: 14px;
  color: #fff;
  display: none;
}

.checkbox-container input[type="checkbox"]:checked+label::after {
  display: inline-block;
}

/* Clear Filters Button */
.clear-button {
  padding: 6px 12px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #c82333;
}

/* Columns Layout */
.columns {
  display: flex;
  gap: 20px;
  flex: 1; /* Let the columns take up all available vertical space */
  overflow: hidden; /* Prevent content overflow */
}


/* Individual Column Styling */
.column {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow vertical scrolling inside the column */
}

.column.artist-list {
  flex: 0.8; /* Maintain proportional width if using flex */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent overflow spilling visually */
}

.artist-list-scrollable {
  flex: 1; /* Take up remaining space in the column */
  overflow-y: auto; /* Add vertical scrolling */
  padding-right: 10px; /* Add space for scrollbar */
}

/* Optional: Customize scrollbar appearance for artist list */
.artist-list-scrollable::-webkit-scrollbar {
  width: 6px; /* Scrollbar width */
}

.artist-list-scrollable::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar color */
  border-radius: 3px; /* Rounded scrollbar edges */
}

.artist-list-scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #007bff; /* Hover color for scrollbar */
}

.column:nth-child(2) {
  flex: 1.2;
}

.column:nth-child(3) {
  flex: 2;
}

/* Properties Section */
.properties {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  gap: 15px; /* Space between title, checkboxes, and button */
}


.properties-title {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-right: 10px; /* Add small space between title and first checkbox */
}

.checkbox-container-group {
  display: flex;
  gap: 15px; /* Space between each checkbox */
  flex-wrap: wrap; /* Wrap checkboxes if they don't fit */
}

.clear-properties-button {
  margin-left: 10px; /* Place button close to All Songs */
  padding: 6px 12px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.clear-properties-button:hover {
  background-color: #c82333;
}

.tag-dropdown {
  flex-grow: 1; /* Allow dropdown to grow and take remaining space */
  position: relative;
}

.remove-tag-button:hover {
  color: #ddd;
}

.tag-filter-container {
  display: flex;
  align-items: center; /* Align tags and dropdown vertically in the center */
  gap: 8px; /* Space between tags and dropdown */
  flex-wrap: nowrap; /* Prevent tags and dropdown from wrapping */
}
.selected-tags {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between individual tags */
}

.tag-chip {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 0.9rem;
  background-color: #007bff;
  color: white;
  border-radius: 16px;
  margin: 4px 0;
}

.remove-tag-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  margin-left: 8px;
  cursor: pointer;
}

/* Tag Dropdown Container */
.dropdown-list {
  position: absolute;
  top: 100%; /* Place it below the input */
  left: 0;
  width: 100%; /* Match the width of the input */
  max-height: 200px; /* Limit the height for scrollable content */
  overflow-y: auto; /* Allow scrolling if content exceeds max height */
  background-color: white; /* White background for dropdown */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 4px; /* Slightly round the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow for depth */
  z-index: 10; /* Ensure it appears above other content */
  padding: 5px; /* Add some spacing inside */
}

/* Individual Dropdown Item */
.dropdown-item {
  padding: 8px 12px; /* Space around each item */
  font-size: 14px; /* Adjust font size for readability */
  cursor: pointer; /* Change cursor to pointer for clickable items */
  transition: background-color 0.3s, color 0.3s; /* Smooth hover effects */
  color: #333; /* Default text color */
}

/* Hover Effect for Dropdown Item */
.dropdown-item:hover {
  background-color: #007bff; /* Highlight color on hover */
  color: white; /* Contrast text color on hover */
}

/* Add some spacing around dropdown items for better clarity */
.dropdown-item + .dropdown-item {
  margin-top: 2px; /* Space between items */
}

.triple-state-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .checkbox-box {
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  /* False State (Empty) */
  .checkbox-box::after {
    content: '';
    display: none;
  }
  
  /* Neutral State (Light Checkmark) */
  .checkbox-box.indeterminate {
    background-color: #e6f7ff; /* Light blue */
    border-color: #a3d1ff; /* Light blue border */
  }
  
  .checkbox-box.indeterminate::after {
    content: '✓';
    color: #a3d1ff; /* Light blue checkmark */
    font-size: 12px;
    display: block;
  }
  
  /* True State (Bold Checkmark) */
  .checkbox-box.checked {
    background-color: #007bff; /* Bold blue */
    border-color: #007bff;
  }
  
  .checkbox-box.checked::after {
    content: '✓';
    color: white; /* White checkmark */
    font-size: 14px;
    display: block;
  }
  
.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column-reverse; /* Stack notifications from bottom to top */
    gap: 10px;
    z-index: 1000;
  }
  
  .notification {
    padding: 15px;
    border-radius: 5px;
    font-weight: bold;
    opacity: 0.95;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    transform: translateY(0);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .notification.success {
    background-color: #4caf50; /* Green for success */
  }
  
  .notification.error {
    background-color: #d32f2f; /* Darker red for readability */
    color: #fff !important; /* Force white text color */
  }
  
  .copy-button,
  .close-button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .close-button {
    margin-left: auto;
    font-size: 1.2rem;
  }
  
  /* Animation for exiting notifications */
  .notification-exit {
    transform: translateY(-20px); /* Move up when closing */
    opacity: 0;
  }
  
/* AlbumEdit Component Styling */
.album-edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .album-header {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
  
  .album-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  
  .album-name-edit {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
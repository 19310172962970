/* Playtree Manager Container */
.playtree-manager {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 72px); /* Full viewport height minus the header height */
    box-sizing: border-box;
    overflow: hidden; /* Prevent content from spilling outside */
}

/* Filter Bar */
.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
}

.search-box {
    width: 70%;
    padding: 8px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.search-box:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Create Playtree Section */
.create-playtree-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.create-playtree-name {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.create-playtree-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.create-playtree-button:hover {
    background-color: #218838;
}

/* Columns Layout */
.columns {
    display: flex;
    gap: 20px;
    flex: 1; /* Let the columns take up all available vertical space */
    overflow: hidden; /* Prevent content overflow */
}

/* Individual Column Styling */
.column {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Allow vertical scrolling inside the column */
}

/* Playtree List Specific Styles */
.playtree-list-content {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex-grow: 1;
}

.playtree-list-content li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.playtree-list-content li:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.playtree-list-content li.selected {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
}

/* Playtree Name */
.playtree-name {
    font-size: 16px;
    flex-grow: 1;
}

/* Playtree Details Header */
.playtree-header {
    display: flex;
    align-items: center; /* Vertically center all elements */
    justify-content: space-between; /* Space between the image and the info */
    gap: 20px; /* Spacing between elements */
    margin-bottom: 20px;
}

/* Playtree Info (Title and Button) */
.playtree-info {
    flex: 1; /* Allow the info section to take up remaining space */
    display: flex;
    flex-direction: row; /* Align title and button horizontally */
    align-items: center; /* Vertically align content */
    justify-content: space-between; /* Push the button to the far right */
}

.playtree-info h4 {
    margin: 0;
    font-size: 1.5rem;
    text-align: left; /* Align the title to the left */
}

.playtree-info button:hover {
    background-color: #0056b3;
}

/* Edit Input Field */
.edit-input {
    padding: 8px;
    font-size: 14px;
    margin-right: 20px; /* Add space between input and buttons */
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: auto; /* Let the input take up a suitable width */
}

/* Edit Buttons Container */
.edit-buttonsPlaytrees {
    display: flex;
    gap: 10px; /* Add spacing between Save and Cancel buttons */
    align-items: center;
}


.edit-buttonPlaytrees,
.cancel-buttonPlaytrees,
.delete-buttonPlaytrees {
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    min-width: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-buttonPlaytrees {
    background-color: #007bff;
    color: white;
}

.edit-buttonPlaytrees:hover {
    background-color: #0056b3;
}

.cancel-buttonPlaytrees,
.delete-buttonPlaytrees
 {
    background-color: #dc3545;
    color: white;
}

.cancel-buttonPlaytrees:hover {
    background-color: #c82333;
}

/* Playtree Records Count */
.playtree-records {
    color: #888;
    font-size: 14px;
    text-align: right;
}

/* Songs Table */
.playlist-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.playlist-table th,
.playlist-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.playlist-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.playlist-table tbody tr:hover {
    background-color: #f9f9f9;
}

.playlist-table td {
    color: #333;
}

/* Remove Button */
.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333;
}

/* Intermediate Table */
.intermediate-table {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
}
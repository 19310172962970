.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensures the form uses the full width of its container */
}

.login-container label {
  width: 100%; /* Ensures labels take up the full width of the form */
  margin-bottom: 10px;
  text-align: left; /* Align label text to the left */
  font-weight: bold;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-container button:hover {
  background-color: #0056b3;
}

.login-container button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.error {
  color: red;
  margin-bottom: 15px;
}

.cover-art-editor {
  text-align: center;
  margin: 20px 0;
}

.cover-art {
  max-width: 100px;
  height: 100px;
  border-radius: 10px;
}

.edit-link-container {
  margin-top: 5px;
}

.edit-link {
  color: #007bff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-link:hover {
  color: #0056b3;
}

.edit-link:disabled {
  color: #cccccc;
  cursor: not-allowed;
}

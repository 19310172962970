/* General styling for the artist edit component */
.artist-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.artist-header {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.artist-image {
  width: 100px; /* Reduce the size of the artist image */
  height: 100px; /* Ensure the image stays proportional */
  border-radius: 50%;
  object-fit: cover;
}

.artist-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px; /* Reduce gap between elements */
}

.artist-name {
  font-size: 1.2rem; /* Slightly smaller font for the name */
  font-weight: bold;
  margin-bottom: 5px; /* Space between name and song count */
}

.artist-songs-count {
  font-size: 0.9rem; /* Smaller font for the song count */
  color: #888; /* Lighter color for a "note" appearance */
  margin: 0; /* Remove any default margins to align closely with the name */
}


.display-mode {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-mode {
  display: flex;
  align-items: center;
  gap: 5px;
}

.name-input {
  font-size: 1.5rem;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.icon {
  cursor: pointer;
  font-size: 1.2rem;
}

.save-icon {
  color: green;
}

.cancel-icon {
  color: red;
}

.edit-icon {
  color: #666;
}

/* Styling for lists */
.albums-list-container, .songs-list-container {
  width: 100%;
}

.albums-list, .songs-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 3px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.list-item:hover {
  background-color: #f1f9ff;
}

.list-item.selected {
  background-color: #d1e7ff;
  border-color: #007bff;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

/* Ensure only one selected item is highlighted */
.list-item.selected:not(:hover) {
  background-color: #d1e7ff;
}

.album-name, .song-title {
  font-weight: bold;
}

.album-tracks, .song-duration {
  font-size: 0.9rem;
  color: #555;
}

.search-songs-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.search-songs-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.song-edit {
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  .song-edit h3 {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .song-edit p {
    margin: 10px 0;
    font-size: 1rem;
    color: #333;
  }
  
  .checkbox-container-song {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkbox-container-song input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .checkbox-container-song input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .checkbox-container-song input[type="checkbox"]:checked::after {
    content: '✓';
    font-size: 16px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .checkbox-container-song input[type="checkbox"]:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  

  .edit-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }

.input-modern {
  font-size: 1rem;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  width: 100%; /* Adjust this width if needed */
  max-width: 300px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-modern:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.input-modern:hover {
  border-color: #0056b3;
}

.button-container {
    display: flex;
    gap: 10px;
  }
  
  .cancel-button {
    background-color: #dc3545;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }


  .tags-containerSong {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between "Tags:" and the tags or input */
  }
  
  .tags-displaySong {
    font-size: 1rem;
    color: #333;
    white-space: nowrap; /* Prevent text wrapping for tags */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  }
  
  .tags-edit-containerSong {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between input and tag chips */
    flex-wrap: wrap; /* Allow tags to wrap to a new line if needed */
  }
  
  .tag-containerSong {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 0; /* Align tags with the input */
  }
  
  .tag-chipSong {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 0.9rem;
    background-color: #007bff;
    color: white;
    border-radius: 16px;
    margin: 4px 0;
  }
  
  .remove-tag-buttonSong {
    background: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    margin-left: 8px;
    cursor: pointer;
  }
  
  .remove-tag-buttonSong:hover {
    color: #ddd;
  }
  
  .tag-dropdownSong {
    position: relative;
    width: 100%;
    max-width: 300px;
  }
  
  .tag-dropdownSong input[type="text"] {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .dropdown-listSong {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
  }
  
  .dropdown-itemSong {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .dropdown-itemSong:hover {
    background-color: #f0f0f0;
  }
  
  .song-image-containerSong {
    display: flex;
    margin-bottom: 20px; /* Space below the image */
  }
  
  .song-image-placeholderSong {
    width: 150px; /* Match placeholder dimensions to the image */
    height: 150px;
    background-color: #ddd; /* Light gray background for placeholder */
    border-radius: 8px; /* Same rounding as the image */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: #555; /* Slightly darker gray text */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
  }
  .audio-player {
    margin-top: 20px;
    width: 100%;
}

.controls {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.audio-button {
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.audio-button:hover {
    background-color: #0056b3;
}

.audio-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.timeline {
    width: 100%;
    appearance: none;
    background-color: #ccc;
    height: 5px;
    border-radius: 5px;
    outline: none;
    transition: background-color 0.3s;
}

.timeline::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.timeline::-webkit-slider-thumb:hover {
    background-color: #0056b3;
}

.time-display {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.9rem;
    color: #333;
}

.volume-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
}

.volume-slider {
  width: 150px;
  appearance: none;
  height: 5px;
  border-radius: 5px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.volume-slider:hover {
  background-color: #bbb;
}

.volume-slider::-webkit-slider-thumb {
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.volume-slider::-webkit-slider-thumb:hover {
  background-color: #0056b3;
}

.delete-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.delete-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c82333;
}

.deleted-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #dc3545;
  text-align: center;
}

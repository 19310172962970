.artist-list-content {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.artist-list-content li {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.artist-list-content li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.artist-list-content li.selected {
  background-color: #007bff; /* Highlight background color */
  color: #ffffff; /* Change text color when selected */
  border-color: #007bff; /* Match the border to the highlight */
}

.deleted-artist {
  background-color: #ff7575 !important; /* Light red background */
  color: #000000; /* Change text color when selected */
  border: 1px solid #fd0000; /* Slightly darker border */
}

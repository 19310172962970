/* Playlist Manager Container */
.playlist-manager {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 72px); /* Full viewport height minus the header height */
    box-sizing: border-box;
    overflow: hidden; /* Prevent content from spilling outside */
}

/* Filter bar */
.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
}

.search-box {
    width: 60%;
    padding: 8px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.search-box:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

.genre-dropdown {
    width: 30%;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.genre-dropdown:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Columns Layout */
.columns {
    display: flex;
    gap: 20px;
    flex: 1; /* Let the columns take up all available vertical space */
    overflow: hidden; /* Prevent content overflow */
}

/* Individual Column Styling */
.column {
    flex: 1;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* Playlist List Specific Styles */
.playlist-list-content {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex-grow: 1;
}

.playlist-list-content li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.playlist-list-content li:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.playlist-list-content li.selected {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
}

.playlist-track-count {
    color: #888;
    font-size: 14px;
    text-align: right;
}

/* Playlist Name */
.playlist-name {
    font-size: 16px;
    flex-grow: 1;
}

/* Playlist Details Header */
.playlist-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.playlist-header .search-box {
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.playlist-header .search-box:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Playlist Info */
.playlist-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Ensure text alignment is centered */
}

.playlist-info h4 {
    margin: 0;
    font-size: 1.5rem;
    text-align: center; /* Explicitly center the text */
}

.playlist-info p {
    margin: 0;
    color: #666;
    text-align: center; /* Explicitly center the text */
}


/* Songs Table */
.playlist-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.playlist-table th,
.playlist-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.playlist-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.playlist-table tbody tr:hover {
    background-color: #f9f9f9;
}

.playlist-table td {
    color: #333;
}

.playlist-table td[colspan="3"] {
    text-align: center;
    font-style: italic;
    color: #999;
}

/* Remove Song Button */
.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333;
}

/* Editable Input Fields */
.edit-input {
    padding: 8px;
    font-size: 14px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-input:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}


/* Add Button for Adding Songs */
.add-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-button:hover {
    background-color: #218838;
}

/* Search Bar */
.column .search-box {
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.column .search-box:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Column Table Scrollable */
.column {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Allow vertical scrolling inside the column */
}

/* Table in Third Column */
.playlist-table {
    width: 100%;
    border-collapse: collapse;
}

.playlist-table th,
.playlist-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.playlist-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.playlist-table tbody tr:hover {
    background-color: #f9f9f9;
}

.playlist-table td {
    color: #333;
}

/* Remove Button */
.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333;
}

/* Intermediate Table */
.intermediate-table {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
}

/* Create Playlist Section */
.create-playlist-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.create-playlist-name,
.create-playlist-type {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-playlist-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.create-playlist-button:hover {
    background-color: #218838;
}

tr.marked-for-removal {
    background-color: #ffcccc; /* Light red for marked rows */
}

.create-playlist-label {
    font-weight: bold;
    margin-right: 10px; /* Add spacing between label and Name input */
}
.cancel-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #c82333;
}
